export const APP_NAME_ID_MAP = {
  premium: 1,
  admin: 2,
  bmwgroup: 14,
  bmwyuc: 17,
  mini: 23,
  vwn: 25,
  bmwcee: 26,
  auto_auctions: 27,
  mp: 28,
  audi: 31,
  parts: 33,
  alphabet: 35,
  quickbuy: 37,
  aggregatebid: 54
}
// info: if you made a change here remember to update `./APP_ID_NAME_MAP.ts`
